header {
  position: fixed;
  top: -37px;
  left: 0;
  width: 100%;
  z-index: 11;
  letter-spacing: .15em;
  padding: 0 64px;
  transition: .4s;
  &:after {
    content: '';
    position: absolute;
    top: 88px;
    left: 32px;
    right: 32px;
    height: 70px;
    background: #fff;
    pointer-events: none;
    opacity: 0;
    transition: .4s;
    z-index: -2;
  }
  &.scroll {
    top: -88px;
    &:after {
      opacity: 1;
      transition: top .4s .3s, height .6s;
    }
    &.open_drop {
      &:after {
        height: 175px;
      }
    }
  }
  .page {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -132px;
      left: 0;
      right: 0;
      height: 1px;
      background: rgba(#fff,.2);
    }
  }
  .responsive_wrap {
    .contetn_resp {
      flex-grow: 1;
      display: flex;
    }
  }
  .bot_resp {
    display: inline-flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .header_contact {
    display: none;
  }
  a {
    padding: 10px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    svg:not(.doc_svg):not(.logo_olir_svg) {
      content: '';
      position: absolute;
      top: 6px;
      left: 5px;
      width: 12px;
      height: 18px;
      z-index: -1;
      transition: .45s cubic-bezier(.165,.84,.44,1), fill 1.5s;
      transform: translate(-5px, -10px);
      opacity: 0;
      transform-origin: 0 0;
    }
    svg {
      fill: $y;
    }
    &:not(.active):not(.logo_olir_svg):hover {
      svg {
        transform: translate(0);
        opacity: 1;
      }
    }
    &.active {
      color: $y;
    }
  }

  .pdf {
    margin: auto 42px auto auto;
    padding-left: 28px;
    top: 100px;
    z-index: 5;
    svg {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -11px;
      fill: $y;
    }
    span {
      border-bottom: 1px solid transparent;
      padding: 0 0 2px;
      transition: .2s;
    }
    &:hover span {
      border-color: $y;
    }
  }


}

#menu_btn {
  width: 26px;
  height: 19px;
  position: relative;
  top: 115px;
  display: none;
  span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 3px;
    width: 100%;
    background: #fff;
    transition: .3s;
    &.middle {
      top: 8px;
    }
    &.bottom {
      width: 18px;
      top: 16px;
      left: 8px;
    }
  }
}
#header_navigation {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 109px;
  left: 0;
  z-index: 2;
  .item {
    margin: 0 15px;
    position: relative;
    display: inline;
    &.active {
      color: $y;
    }
    a {
      margin: 0;
    }
    &.open .drop_menu {
      pointer-events: auto;
      a {
        opacity: 1;
        &:nth-child(1) {
          transition-delay: 0s;
        }
        &:nth-child(2) {
          transition-delay: 0.1s;
        }
        &:nth-child(3) {
          transition-delay: 0.2s;
        }
      }
    }
  }
  .drop_menu {
    position: absolute;
    top: 26px;
    left: 0;
    text-align: left;
    line-height: 30px;
    pointer-events: none;
    a {
      white-space: nowrap;
      display: block;
      padding: 0 10px;
      margin: 0;
      opacity: 0;
      transition: 0.3s ease;
      &:nth-child(1) {
        transition-delay: 0.2s;
      }
      &:nth-child(2) {
        transition-delay: 0.1s;
      }
      &:nth-child(3) {
        transition-delay: 0s;
      }
      &:hover {
        color: $y;
      }
    }
  }
}
#logo {
  position: relative;
  top: 65px+37px;
  z-index: 3;
  padding: 0;
  height: 38px;
  width: 102px;
  opacity: 0;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    transition: .3s;
    height: 38px;
    &.logo_olir_svg1 {
      width: 38px;
    }
    &.logo_olir_svg2 {
      width: 100%;
    }
    .text {
      fill: #fff;
    }
  }
  #drib_logo {
    transition: 1s;
    transform-origin: 50%;
    path {
      transform-origin: 50%;
      transition: .4s;
    }
  }
  &:hover {
    #drib_logo {
      transform: rotate(360deg);
      path {
        transform: scale(.8);
      }
    }
  }
}
#lang {
  position: relative;
  top: 109px;
  z-index: 2;
  a {
    padding: 10px 8px;
    svg:not(.doc_svg) {
      left: 2px;
    }
    &.active {
      color: $y;
    }
  }
}
.dark header {
  a {
    color: #000;
  }
  #logo_preload,
  #logo {
    .text {
      fill: #0a5993 !important;
    }
  }
  .page:after {
    content: none;
  }
  #menu_btn span {
    background: #000;
  }
}




/*.bg_gradient {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 1.5s;
}*/
/*#bg_gradient_1 {
  background: linear-gradient(180deg, $y 0%, #e9893f 47%, #ffca08 100%);
}
#bg_gradient_2 {
  background: linear-gradient(180deg, $o 0%, #e86f4a 47%, #ff7827 100%);
}
#bg_gradient_3 {
  background: linear-gradient(180deg, $b 0%, #207fa6 47%, #2c8eb7 100%);
}
#bg_gradient_4 {
  background: linear-gradient(180deg, $l 0%, #2a9cc5 47%, #3dc0ee 100%);
}*/


.container {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 32px;
  transition: 3s;
  background: $y;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
footer {
  margin: auto auto 0;
  position: relative;
  &.hideFooter {
    opacity: 0;
    display: none;
  }
}


#slides_main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#slides_main_wrapp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video_index {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.svg-mask-common {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  /*pointer-events: none;
  opacity: 0;*/
}
#svg_mask_resp {
  display: none;
  width: 600px;
  height: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  .mask {
    transform-origin: 0 0;
    transform: scale(10);
    transition: transform .7s ease-out;
  }
}
.go_drib {
  #svg_mask_resp {
    .mask {
      transform: scale(1) translate(0);
    }
  }
}
.drib_svg_part {
  transform-origin: 50%;
}
.svg-mask-common mask rect,
.svg-mask-common mask .circle {
  transition: 2s;
}

.svg-mask-common mask rect,
.svg-mask-common mask .circle_gray {
  fill: rgba(255, 255, 255, 1);
}
.svg-mask-common > rect,
.svg-mask-common > circle {
  fill: rgba(246, 246, 246, 1);
}

#drib_border_1_r,
#drib_border_2_r,
#drib_border_1,
#drib_border_2 {
  transform-origin: 50%;
  transform: scale(.6);
  opacity: 0;
  transition: .3s;
  transition-delay: 0s;
}

#drib_border_1_r,
#drib_border_1 {
  transform: scale(.4);
  transition-delay: 0s;
}

#drib_1,
#drib_2,
#drib_3,
#drib_4 {
  opacity: 0;
  transform: translate(-20px, -30px);
  transition: .6s;
  transition-delay: 0s;
}


.drib_mask {
  transform: scale(18);
  transform-origin: 20% 58%;
  transition: transform 1.4s ease-out;
}

#play_mini,
#play {
  cursor: pointer;
  opacity: 0;
  transform: scale(1.4);
  transition: .7s;
  transition-delay: 0s;
  transform-origin: 24% 58%;
}

.slide_main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &.visible {
    z-index: 2;
    h2 {
      transition: .3s 1.8s;
      transform: translateY(0);
      opacity: 1;
      svg {
        transition: .5s 2s;
        transform: translate(0, 0);
        opacity: 1;
      }
    }
    .skip {
      transition: .3s 1.8s;
      transform: translateY(0);
      opacity: 1;
    }
  }
  .text {
    position: relative;
    margin: 0 0 58px 200px;
    z-index: 3;
    transition: .6s;
  }
  h2 {
    letter-spacing: .1em;
    font-size: 48px;
    line-height: 60px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 41px;
    transition: .3s;
    transform: translateY(-20px);
    opacity: 0;
    svg {
      position: absolute;
      top: -63px;
      left: -39px;
      z-index: -1;
      transition: .3s;
      transform: translate(-15px, -30px);
      opacity: 0;
    }
  }
  .skip {
    color: #fff;
    position: relative;
    font-size: 12px;
    font-weight: 300;
    padding: 20px 20px 20px 97px;
    letter-spacing: .1em;
    transition: .3s;
    transform: translateY(20px);
    opacity: 0;
    span {
      transition: .2s;
      display: inline-block;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      height: 1px;
      width: 64px;
      background: #fff;
      transition: .2s;
    }
  }
}

@media all and (min-width: 1025px) {
  .slide_main .skip:hover {
    span {
      transform: translateX(15px);
    }
    &:after {
      width: 74px;
    }
  }
}

.content_slide_main {
  overflow: hidden;
  transition: .4s .1s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../img/bg_video.png) repeat;
    z-index: 2;
  }
}


#nav_slides_main {
  position: absolute;
  bottom: 50px;
  left: 42px;
  text-align: center;
  z-index: 3;
}
.item_nav_slides_main {
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  margin: 17px 0;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -42px;
    width: 0;
    height: 2px;
    background: $y;
    transition: opacity .6s, width .3s, background 1.5s;
    opacity: 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -5px 0 0 -5px;
    width: 7px;
    height: 7px;
    border: 1px solid #f8f8f8;
    border-radius: 100%;
    opacity: 1;
    transform: scale(1);
    transition: transform .55s .2s cubic-bezier(.165,.84,.44,1),opacity .45s .2s cubic-bezier(.165,.84,.44,1);
  }
  span {
    opacity: 0;
    transition: transform .7s cubic-bezier(.23,1,.32,1),opacity .45s cubic-bezier(.165,.84,.44,1);
    display: inline-block;
    &.f {
      transform: translateY(-10px);
    }
    &.l {
      transform: translateY(10px);
    }
  }
  &.active {
    span {
      opacity: 1;
      transition: transform .7s .2s cubic-bezier(.23,1,.32,1),opacity .45s .2s cubic-bezier(.165,.84,.44,1);
      &.f,
      &.l {
        transform: translateY(0);
      }
    }
    &:before {
      width: 41px;
      opacity: 1;
    }
    &:after {
      opacity: 0;
      transform: scale(1.3);
      transition: transform .55s cubic-bezier(.165,.84,.44,1),opacity .45s cubic-bezier(.165,.84,.44,1);
    }
  }
}

.blockScroll {
  .item_nav_slides_main:before {
    width: 0;
    opacity: 1;
  }
}

#arrow,
#next_slide_main {
  cursor: pointer;
  width: 43px;
  height: 43px;
  display: block;
  border: 1px solid #fff;
  border-radius: 100%;
  background: url(../img/next_slide.svg) center no-repeat;
  margin: 33px 0 0;
}

#arrow {
  position: absolute;
  bottom: 73px;
  left: 50%;
  border-color: #000;
  margin: 0 0 0 -22px;
  background: url(../img/arrow.svg) center no-repeat;
  opacity: 0;
  transition: .6s;
  transform: translateY(-35px);
  z-index: 4;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -1px;
    bottom: 0;
    width: 1px;
    height: 0;
    background: #000;
    transition: .5s;
    transition-delay: .6s;
  }
  &.go_an {
    transform: translateY(0);
    opacity: 1;
    &:after {
      bottom: -68px;
      height: 46px;
    }
  }
}



#content_block {
  background: #fff;
  flex-grow: 1;
  top: 0;
  left: 0;
  z-index: 10;
  /*height: 100%;*/
}
.index_page {
  #logo {
    opacity: 0;
  }
  #full_screen,
  #content_block {
    background: none;
  }
  .page {
    position: relative;
    z-index: 4;
  }
  #full_screen {
    .wrapp {
      opacity: 0;
      transform: translateY(-30px);
      transition: .4s;
      transition-delay: 0s;
    }
    .page {
      position: static;
      padding-top: 0;
    }
    #nav_text_slider {
      opacity: 0;
      transform: translateY(30px);
      transition: .4s;
      transition-delay: 0s;
    }
  }
  #content_block {
    /*opacity: 0;*/
    visibility: hidden;
    pointer-events: none;
    transition-delay: 1s;
  }
  #under {
    background: #fff;
    padding-top: 70px;
    display: none;
    .title_page {
      font-size: 48px;
      letter-spacing: .1em;
    }
    .partners {
      padding: 14px 0 0;
      width: 100%;
      left: 0;
      &:after {
        content: none;
      }
    }
  }

  #wave_full_screen {
    opacity: 0;
    transform: translate(0, 100%);
    &.go_an {
      transform: translate(0, 70%);
      opacity: 1;
    }
  }
}


.go_drib {
  .drib_mask {
    transform: scale(1);
  }
  #play_mini,
  #play {
    opacity: 1;
    transform: scale(1);
    transition-delay: 1.3s;
  }

  #drib_border_1_r,
  #drib_border_2_r {
    opacity: 1;
    transform: scale(1);
    transition-delay: .9s !important;
    transition: .2s;
  }
  #drib_border_1,
  #drib_border_2 {
    opacity: 1;
    transform: scale(1);
    transform-origin: 24% 64%;
    transition-delay: 1s !important;
    transition: .9s;
  }
  #drib_border_1_r {
    transition: .2s;
    transition-delay: .8s !important;
  }
  #drib_border_1 {
    transition-delay: 1.1s !important;
    transition: .9s;
  }
  #drib_1,
  #drib_2,
  #drib_3,
  #drib_4 {
    opacity: 1;
    transform: translate(0, 0);
    transition-delay: 1.3s;
  }
  #drib_1 {
    transition-delay: 1.6s;
  }
  #drib_2 {
    transition-delay: 1.5s;
  }
  #drib_3 {
    transition-delay: 1.7s;
  }
  &.index_page {
    #content_block {
      visibility: visible;
      pointer-events: auto;
      transition-delay: 0s;
    }
    #under {
      /*display: block;*/
    }
    #full_screen {
      .wrapp,
      #nav_text_slider {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 1.5s;
      }
    }
  }
  .slide_main {
    .text {
      opacity: 0;
    }
  }
}



#wave_full_screen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  transition: .6s;
  transform: translate(0, 75%);
}

#full_screen {
  overflow: hidden;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: .1s;
  .page {
    padding-top: 80px;
  }
  .title_page {
    position: relative;
    z-index: 2;
    letter-spacing: .1em;
  }
  .flex_block {
    padding: 0;
    position: relative;
    z-index: 2;
    .right {
      margin: auto 0 auto auto;
    }
  }
  .left {
    width: 420px;
    position: relative;
    z-index: 3;
  }
  .page_content {
    position: relative;
    margin-bottom: 30px;
  }
  .drib_svg {
    position: absolute;
    left: -129px;
    top: -270px;
  }
  #b-cargo-t {
    height: auto !important;
    width: 100% !important;
  }
  #h_and_s {
    /*position: absolute;
    top: 142px;
    left: -50%;*/
    /*width: 650px;*/
    /*height: 440px;*/
    height: auto !important;
    width: 100% !important;
  }
  #laboratory_svg {
    /*position: absolute;
    top: 76px;
    left: -37%;*/
    /*width: 490px;*/
    /*height: 500px;*/
    height: auto !important;
    width: 100% !important;
  }
  #fles {
    /*position: absolute;
    top: 129px;
    left: -31%;*/
    /*width: 574px;*/
    /*height: 468px;*/
    height: auto !important;
    width: 100% !important;
  }
  .h_and_s {
    .svg_color {
      fill: $y;
    }
  }
  .laboratory {
    .svg_color {
      fill: $o;
    }
  }
  .fles {
    .svg_color {
      fill: #1c95c0;
    }
  }

  .ill_top.about_ill {
    top: 110px;
    left: 5%;
    bottom: 5%;
    position: absolute;
    width: auto;
    /*z-index: 7;*/
    justify-content: flex-start !important;
    display: flex;
    align-items: stretch;
    .about_p {
      height: 100% !important;
      width: auto;
      /*max-width: none;*/
      opacity: 0;
      /*pointer-events: none; */
    }
    #about_svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100% !important;
    }
  }
  .tooltip {
    position: absolute;
    top: 46%;
    left: 59%;
    z-index: 10;
    transform: translate(-30px, -40px);
    opacity: 0;
    transition: .5s;
    transition-delay: .4s;
    &.go {
      opacity: 1;
      transform: translate(0);
    }
    .ico {
      cursor: pointer;
      width: 31px;
      height: 52px;
      svg {
        &.ico-plus {
          width: 15px;
          height: 15px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;
          margin: 0 auto;
          .plus {
            transform-origin: 50%;
            transition: .3s;
          }
        }
      }
      .ico-plus:hover {
        .plus {
          transform: rotate(90deg);
        }
      }
    }
    &.tooltip_2 {
      top: 28%;
      left: 64%;
      transition-delay: .2s;
    }
    &.tooltip_3 {
      top: 70%;
      left: 26%;
    }
    .text {
      position: absolute;
      top: -3px;
      left: 49px;
      width: 242px;
      border-radius: 5px;
      background-color: #ffffff;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      padding: 10px 15px;
      opacity: 0;
      transition: .3s;
      pointer-events: none;
    }
    &.open {
      .text {
        opacity: 1;
        left: 39px;
        pointer-events: auto;
      }
    }
  }
  .about_page {
    position: static !important;

    .flex_block {
      position: static;
      .right {
        margin: auto 0 5% auto;
      }
    }
    .description_page {
      font-size: 14px;
      line-height: 30px;
      z-index: 6;
      .title_page {
        font-size: 36px;
        margin: 0 0 26px;
        padding-top: 15px;
      }
    }
    .svg_color {
      fill: #e95223;
    }
  }
  .about_left {
    p:last-child {
      margin-bottom: 0;
    }
  }
  #about_svg {
    /*position: absolute;
    left: -49%;
    top: 75px;
    width: 655px;
    height: 468px;*/
    /* height: auto !important; */
    /*width: 100% !important;*/
  }
}
.internal_pages #full_screen {
  justify-content: flex-start;
  .title_page {
    margin: 28px 0 60px;
    padding-top: 15px;
  }
  .page {
    padding-top: 127px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    z-index: 5;
    .flex_block {
      padding-bottom: 30px;
      align-items: stretch;
      flex-grow: 1;
      .right {
        /*margin: 0;*/
      }
    }
  }
  .ill_top {
    justify-content: flex-end;
    display: flex;
  }
}

#slider_text_title {
  margin: 0 0 0 41%;
  position: relative;
  z-index: 4;
  .wrapp {
    height: 180px;
    position: relative;
    margin-bottom: 30px;
  }
  .title_page {
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    &.active {
      pointer-events: auto;
    }
  }
}

#nav_text_slider {
  padding: 0 0 0 58px;
  /*margin: 57px 0 0;*/
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 41px;
    height: 2px;
    background-color: $y;
    top: 10px;
    left: 0;
  }
  a {
    color: #4e4e4e;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    margin: 0 17px 0 0;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -5px 0 0 -5px;
      width: 7px;
      height: 7px;
      border: 1px solid #a0a0a0;
      border-radius: 100%;
      opacity: 1;
      transform: scale(1);
      transition: transform .55s .2s cubic-bezier(.165,.84,.44,1),opacity .45s .2s cubic-bezier(.165,.84,.44,1);
    }
    span {
      opacity: 0;
      transition: transform .7s cubic-bezier(.23,1,.32,1),opacity .45s cubic-bezier(.165,.84,.44,1);
      display: inline-block;
      &.f {
        transform: translateY(-10px);
      }
      &.l {
        transform: translateY(10px);
      }
    }
    &.active {
      span {
        opacity: 1;
        transition: transform .7s .2s cubic-bezier(.23,1,.32,1),opacity .45s .2s cubic-bezier(.165,.84,.44,1);
        &.f,
        &.l {
          transform: translateY(0);
        }
      }
      &:before {
        width: 41px;
        opacity: 1;
      }
      &:after {
        opacity: 0;
        transform: scale(1.3);
        transition: transform .55s cubic-bezier(.165,.84,.44,1),opacity .45s cubic-bezier(.165,.84,.44,1);
      }
    }
  }
}

.title_page {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
  letter-spacing: .1em;
  padding-top: 15px;
}








#under {
  .title_page {
    text-align: center;
    font-size: 36px;
    &.tal {
      text-align: left;
      margin: 76px 0 0;
    }
  }
  .services {
    padding-top: 120px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 100px;
    }
    .ill {
      width: 50%;
      text-align: right;
      svg {
        width: 85%;
      }
      &.tal {
        text-align: left;
        svg {
          width: 75%;
        }
      }
      &.lw {
        text-align: left;
      }
    }
    .text {
      padding: 77px 0 0;
      width: 36%;
      .title {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: .2em;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: -44px;
          left: -30px;
          width: 50px;
          height: 82px;
          background: url(../img/drib_news.svg) / 100% no-repeat;
          z-index: -1;
          opacity: 0;
          transition: .3s;
        }
        &:hover {
          &:before {
            top: -25px;
            left: -25px;
            opacity: 1;
          }
        }
      }
      p {
        font-size: 14px;
        line-height: 30px;
        margin: 34px 0 44px;
        font-weight: 300;
      }
      .link {
        position: relative;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: .2em;
        display: inline-block;
        padding-left: 46px;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -1px;
          height: 1px;
          width: 30px;
          background: $y;
          transition: .3s;
        }
        &:hover {
          color: $y;
          &:after {
            width: 46px;
            left: -16px;
          }
        }
      }
    }
  }
}



.color_theme_1 {
  .container {
    background: $y;
  }
  .item_nav_slides_main:before {
    background: $y;
  }
  /*&:not(.internal_pages) {
    header .pdf svg,
    #header_navigation a svg,
    #lang a svg {
      fill: $y;
    }
    header a.active {
      color: $y;
    }
  }

    header .pdf span:hover {
      border-color: $y;
    }*/
}
.color_theme_2 {
  .container {
    background: $o;
  }
  .item_nav_slides_main:before {
    background: $o;
  }
  /*&:not(.internal_pages) {
    header .pdf svg,
    #header_navigation a svg,
    #lang a svg {
      fill: $o;
    }
    header a.active {
      color: $o;
    }
    header .pdf span:hover {
      border-color: $o;
    }
  }*/
}
.color_theme_3 {
  .container {
    background: $b;
  }
  .item_nav_slides_main:before {
    background: $b;
  }
  /*&:not(.internal_pages) {
    header .pdf svg,
    #header_navigation a svg,
    #lang a svg {
      fill: $b;
    }
    header a.active {
      color: $b;
    }
    header .pdf span:hover {
      border-color: $b;
    }
  }*/
}
.color_theme_4 {
  .container {
    background: $l;
  }
  .item_nav_slides_main:before {
    background: $l;
  }
  /*&:not(.internal_pages) {
    header .pdf svg,
    #header_navigation a svg,
    #lang a svg {
      fill: $l;
    }
    header a.active {
      color: $l;
    }
    header .pdf span:hover {
      border-color: $l;
    }
  }*/
}




.internal_pages {
  header {
    a {
      color: #000;
      &.active {
        color: $y;
      }
    }
  }
  .title_page {
    line-height: 48px;
    letter-spacing: .2em;
    margin: 0 0 35px;
  }
}



.breadcrumbs {
  position: relative;
  font-weight: 300;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: -100px;
    width: 73px;
    height: 1px;
    background-color: #000;
  }
  a:hover {
    color: #a2a2a2;
  }
}
.description_page {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  transform: translate3d(0, 80px, 0);
  opacity: 0;
  transition: transform 1.5s ease, opacity 1s ease;
}

.statistics {
  display: flex;
  flex-wrap: wrap;
  padding: 82px 26px 92px;
  .item {
    width: 26%;
    margin: 0 9.5% 73px 0;
    .top {
      /*text-transform: uppercase;*/
      font-size: 60px;
      line-height: 48px;
      font-weight: 700;
      span {
        font-size: 18px;
      }
    }
    .bot {
      font-weight: 300;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}



.text_block {
  padding: 92px 26px 50px;
  h2 {
    font-size: 36px;
    font-weight: 900;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: .2em;
    padding: 0 0 47px;
  }
  h3 {
    font-size: 18px;
    font-weight: 900;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: .2em;
    padding: 0 0 23px;
  }
  p {
    font-weight: 300;
    line-height: 30px;
    color: #010101;
  }
}


.image_page {
  position: relative;
  .top, .bot {
    position: absolute;
    left: 0;
  }
  .top {
    top: -2px;
    width: 100%;
  }
  .bot {
    bottom: -1px;
    left: -2%;
    width: 104%;
  }
  img {
    width: 100%;
  }
}

.icon_list {
  display: flex;
  flex-wrap: wrap;
  padding: 45px 26px 33px;
  font-weight: 300;
  .item {
    width: 30%;
    margin: 0 5% 50px 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  .image {
    height: 44px;
    display: inline-flex;
    align-items: center;
    margin: 0 0 20px;
    img {
      vertical-align: middle;
    }
  }
}


#next_service {
  background-color: #f9f9f9;
  overflow: hidden;
  .flex_block {
    padding: 30px 0;
    position: relative;
    align-items: stretch;
  }
  .ill_ns {
    display: flex;
    width: 40%;
    svg {
      width: 100%;
    }
  }
  .left {
    position: relative;
    z-index: 2;
    width: 55%;
    margin: 113px 0 88px;
  }
  .title {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: .2em;
    display: inline-block;
    position: relative;
  }
  .animate {
    .drib_svg {
      transition: all .6s;
    }
    .title:hover {
      & + * + * + .drib_svg {
          transform: translate(10px,20px);
          transition: all .3s;
      }
    }
  }
  .description_page {
    line-height: 30px;
    font-weight: 300;
    margin: 28px 0 51px;
  }
  .link {
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: .2em;
    display: inline-block;
    padding-left: 46px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -1px;
      height: 1px;
      width: 30px;
      background: $y;
      transition: .3s;
    }
    &:hover {
      color: $y;
      &:after {
        width: 46px;
        left: -16px;
      }
    }
  }
  .drib_svg {
    position: absolute;
    left: -54px;
    top: -77px;
    z-index: -1;
  }
  .laboratory {
    .title:before {
      background: $o;
    }
    #next_service_color {
      fill: $o;
    }
  }
  .fles {
    .title:before {
      background: #1c95c0;
    }
    #next_service_color {
      fill: #1c95c0;
    }
  }
  .h_and_s {
    .title:before {
      background:$y;
    }
    #next_service_color {
      fill: $y;
    }
  }
}


.flex_block {
  display: flex;
  justify-content: space-between;
  padding: 80px 0 55px;
  &.page {
    padding: 80px 26px 55px;
  }
  & > div {
    width: 44%;
    padding: 0;
  }
  .icon_list_col {
    padding: 0 20.5% 0 0;
  }
  .about_block {
    .top {
      font-weight: 700;
      font-size: 18px;
      padding: 0 0 20px;
      span {
        font-size: 50px;
        line-height: 50px;
      }
    }
    p {
      font-weight: 300;
      line-height: 30px;
      margin: 0 0 15px;
    }
  }
  .right {
    margin: 0 0 auto auto;
  }
}

.icon_list_col {
  .item {
    display: flex;
    align-items: flex-start;
    margin: 0 0 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .image {
    width: 50px;
    flex-shrink: 0;
    text-align: center;
    padding: 6px 0 0;
  }
  .text {
    padding-left: 38px;
    font-size: 12px;
    line-height: 24px;
    font-weight: 300;
  }
}


.text_block_big {
  padding: 70px 0 0;
  .flex_block {
    padding: 0;
    margin: 0 0 72px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  & + .text_block {
    padding-top: 55px;
  }
}
.range_block {
  .top {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px;
  }
  .year {
    font-size: 60px;
    line-height: 50px;
    font-weight: 700;
    span {
      font-size: 18px;
    }
  }
  .bot {
    font-size: 14px;
    font-weight: 300;
  }
}

#drib_vac {
  position: absolute;
  top: -332px;
  left: -225px;
  z-index: -1;
}

.page_dynamic {
  position: relative;
  margin: 160px auto 115px;
  .title_page {
    margin: 0 0 26px;
  }
  .desc {
    font-weight: 300;
    line-height: 30px;
    margin: 44px 0 17px;
    a {
      color: $y;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.articles {
  .item {
    border-bottom: 1px solid #ebebeb;
    padding: 19px 0 0;
    &:last-child {
      border-bottom: 0;
    }
    .image_link {
      display: block;
      &:hover + .title {
        span {
          border-color: $y;
        }
        &:before {
          top: -25px;
          left: -25px;
          opacity: 1;
        }
      }
    }
    .text {
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
    }
    .title {
      position: relative;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      display: block;
      margin: 0 0 10px;
      padding-top: 19px;
      span {
        transition: .3s;
        border-bottom: 1px solid #D4D4CD;
      }
      &:before {
        content: '';
        position: absolute;
        top: -44px;
        left: -30px;
        width: 50px;
        height: 82px;
        background: url(../img/drib_news.svg) / 100% no-repeat;
        z-index: -1;
        opacity: 0;
        transition: .3s;
      }
      &:hover {
        span {
          border-color: $y;
        }
        &:before {
          top: -25px;
          left: -25px;
          opacity: 1;
        }
      }
    }
    .date {
      color: #a2a2a2;
      font-size: 12px;
      font-weight: 300;
      text-transform: uppercase;
    }
  }
}

.files_block {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
  width: 600px;
  margin: 0 auto 120px;
  .file {
    width: 30%;
    min-height: 43px;
    padding: 4px 0 0 49px;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    position: relative;
    margin-right: 5%;
    .name {
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: .3s;
    }
    &:hover {
      .name {
        color: $y;
      }
    }
    .file_extension {
      color: #b1b1b1;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 33px;
      height: 43px;
      background: url(../img/file_ico.svg) / 100% no-repeat;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}



.page_dynamic_single {
  margin-bottom: 0;
  .breadcrumbs,
  .date,
  p,
  ul,
  ol,
  table,
  blockqoute,
  .title_page,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: 100%;
    width: 600px;
    margin: 0 auto;
  }

  .breadcrumbs {
    margin-bottom: 23px;
    a {
      font-weight: 300;
      font-size: 12px;
    }
    img {
      vertical-align: middle;
      margin: 0 11px;
    }
    &:after {
      content: none;
    }
  }
  .title_page {
    text-transform: none;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0;
    margin-bottom: 13px;
  }
  &.vacancies_page {
    /*.title_page {
      font-size: 48px;
      letter-spacing: .2em;
      text-transform: uppercase;
    }*/
    .breadcrumbs {
      margin-bottom: 13px;
    }
    .text_content {
      margin: 36px 0 0;
    }
  }
  .date {
    color: #a2a2a2;
    font-size: 12px;
    font-weight: 300;
  }

  .text_content {
    margin: 28px 0 0;
    text-size-adjust: 100%;
    img {
      display: block;
      margin: 0 auto 62px;
    }
    p {
      line-height: 36px;
      margin-bottom: 30px;
      font-weight: 300;
      &:empty {
        display: none;
      }
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 25px;
    }
    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 9px;
    }
    b,strong {
      font-weight: 900;
    }
    ul {
      line-height: 30px;
      margin-bottom: 39px;
      font-weight: 300;
      li {
        padding-left: 24px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 15px;
          height: 1px;
          width: 10px;
          background: $y;
        }
      }
    }
    table {
      margin-bottom: 50px;
      background: #f6f6f6;
      border-collapse: collapse;
      font-size: 12px;
      font-weight: 300;
      line-height: 30px;
      td {
        padding: 5px 18px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        &:last-child {
          border-right: 0;
        }
      }
    }
    a {
      color: #ec9400;
    }
  }


}

.responsive_height {
  display: flex;
  flex-direction: column;
}

.pagination {
  border-top: 1px solid #dadada;
  padding: 26px;
  margin: auto 0 0 0;
  .page_content {
    display: flex;
    font-size: 16px;
    svg {
      display: inline-block;
      vertical-align: middle;
      width: 8px;
      height: 13px;
      fill: $y;
      transition: .3s;
    }
    span {
      padding: 0 21px;
      display: inline-block;
      vertical-align: middle;
    }
    .arrow {
      display: inline-block;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 14px;
        height: 1px;
        background: $y;
        transition: .3s;
      }
    }
    .prev {
      .arrow {
        &:after {
          right: -8px;
        }
      }
      &:hover {
        .arrow {
          svg {
            transform: translateX(-8px);
          }
          &:after {
            width: 22px;
          }
        }
      }
    }

    .next {
      margin: auto 0 auto auto;
      .arrow {
        &:after {
          left: -8px;
          background: $y;
        }
      }
      &:hover {
        .arrow {
          svg {
            transform: translateX(8px);
          }
          &:after {
            width: 24px;
          }
        }
      }
    }
    a.disabled {
      color: #a2a2a2;
      pointer-events: none;
      svg {
        fill: #a2a2a2;
      }
      .arrow {
        &:after {
          background: #a2a2a2;
        }
      }
    }
  }

}





#under {
  .about_left {
    margin: 45px 0 0;
  }
}


.deep_water {
  margin: 39px 0 105px;
  .block {
    display: flex;
    justify-content: space-between;
  }
  .ship {
    width: 75%;
    text-align: center;
    margin: -5% 0 0;
    svg {
      width: 78%;
    }
  }
  .title_page {
    font-size: 36px;
    font-weight: 900;
    line-height: 36px;
    margin: 0 0 95px!important;
  }
  .text {
    width: 35%;
    .top {
      font-weight: 700;
      line-height: 18px;
      margin: 0 0 23px;
    }
    .mid {
      font-weight: 700;
      line-height: 60px;
      font-size: 60px;
      span {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .item1 {
      margin: 0 0 32px;
      .mid, .top {
        color: $o;
      }
      .top {
        font-size: 16px;
      }
    }
    .item2 {
      .top {
        font-weight: 300;
        font-size: 14px;
        margin: 0 0 28px;
      }
    }
  }
}


.saving_block {
  position: relative;
  padding: 83px 0 79px;
  &:before,
  &:after {
    content: '';
    height: 1px;
    background: #dadada;
    position: absolute;
    left: -10%;
    right: -10%;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  .title_page {
    font-size: 36px;
    font-weight: 900;
    line-height: 36px;
    margin: 0 0 0 !important;
    padding-bottom: 72px;
  }
  .block {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .item {
      width: 20%;
      margin: 0 6.6% 0 0;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .top {
        font-weight: 700;
        line-height: 30px;
        font-size: 36px;
        padding: 0 0 3px;
        span {
          font-size: 18px;
        }
      }
      .text {
        font-weight: 300;
        line-height: 30px;
      }
    }
  }
}




.power_growth {
  padding: 92px 0 98px;
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 88px;
  }
  .item {
    display: flex;
    align-items: flex-start;
    margin: 0 0 69px;
    &.\--last {
      margin-bottom: 0;
    }
  }
  .barrel {
    margin: 0 78px 0 0;
    img {
      margin-right: 14px;
    }
  }
  .text {
    .top {
      font-weight: 700;
      line-height: 40px;
      font-size: 60px;
      span {
        font-size: 18px;
      }
    }
    .bot {
      font-weight: 300;
    }
  }
  .new {
    color: $o;
    margin: 0 0 0;
  }
}





.gallery_page {
  width: 1372px;
  &.gallery_service {
    padding: 47px 26px 120px;
  }
}
.gallery_block {
  .item {
    display: inline-block;
    margin: 0 16px;
    img {
      width: 100%;
    }
  }
  .arrow {
    cursor: pointer;
    svg {
      transition: .3s;
    }
    &:hover {
      svg {
        fill: $y;
      }
    }
  }
}
.pagination_gallery {
  padding: 27px 0 0 17px;
  .fraction {
    display: none;
    color: #a2a2a2;
    font-size: 16px;
    font-weight: 700;
    margin: 0 10px;
  }
  .current_slide {
    color: #4e4e4e;
    display: inline-block;
  }
  .d {
    display: inline-block;
    margin: 0 8px;
  }
  .arrow {
    display: inline-block;
    margin-right: 20px;
    svg {
      width: 16px;
      height: 12px;
      display: inline-block;
      fill: #a2a2a2;
    }
    &.slick-disabled {
      pointer-events: none;
      opacity: .3;
    }
    &.slick-hidden {
      display: none;
    }
  }
}


.work_principles {
  padding: 101px 0 82px;
  position: relative;
  .title_page {
    font-size: 36px;
    line-height: 36px;
    margin: 0 0 63px !important;
  }
  .block {
    display: flex;
    justify-content: space-between;
    .item {
      width: 26%;
    }
    .img {
      height: 47px;
      display: flex;
      align-items: flex-end;
      margin: 0 0 13px;
    }
    .text {
      font-size: 12px;
      font-weight: 300;
      line-height: 24px;
    }
  }
  &:after {
    content: '';
    height: 1px;
    background: #dadada;
    position: absolute;
    bottom: 0;
    left: -10%;
    right: -10%;
  }
}


.partners {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 96px 0 0;
  .item_partners {
    width: 25%;
    text-align: center;
    margin: 0 0 71px;
    img {
      filter: grayscale(100%);
      transition: .3s;
    }
    &:hover img {
      filter: grayscale(0%);
    }
  }
  .link {
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: .2em;
    display: inline-block;
    padding-left: 46px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -1px;
      height: 1px;
      width: 30px;
      background: $y;
        transition: .3s;
      }
      &:hover {
        color: $y;
        &:after {
          width: 46px;
          left: -16px;
        }
      }
  }
}







footer {
  color: #fff;
  font-size: 12px;
  a {
    color: #fff;
  }
  .top {
    padding: 35px 0 37px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    nav a {
      margin-right: 46px;
      transition: .2s;
      border-bottom: 1px solid transparent;
      &.active,
      &:hover {
        border-color: #fff;
      }
    }
    .right {
      display: flex;
      align-content: center;
      a {
        display: inline-flex;
        align-items: center;
        margin-left: 50px;
        span {
          transition: .2s;
          border-bottom: 1px solid transparent;
          line-height: 15px;
          transform: translateY(1px);
        }
        &:hover {
          img {
            transform: translateY(-5px);
          }
          span {
            border-color: #fff;
          }
        }
      }
      img {
        margin-right: 17px;
        transition: .3s;
      }
    }
  }
  .bot {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 23px 0 0;
    #logo_footer {
      position: absolute;
      left: 50%;
      top: 17px;
      margin-left: -18px;
      img {
        transition: 1s;
      }
      &:hover {
        img {
          transform: rotate(360deg);
        }
      }
    }
  }
  .solar {
    display: inline-flex;
    align-items: center;
    img {
      margin-left: 17px;
    }
    span {
      transition: .2s;
      border-bottom: 1px solid transparent;
      line-height: 15px;
      transform: translateY(1px);
    }
    a:hover span{
      border-color: #fff;
    }
  }
}






.contact_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto !important;
  padding: 160px 0 115px;
  //padding: 175px 0 100px;
  position: relative;
  .title_page {
    margin: 0 0 58px;
    padding-top: 15px;
  }
  .svg-mask-common {
    pointer-events: none;
  }

  #drib_border_1,
  #drib_border_2 {
    transform: scale(1.3);
    opacity: 1;
  }
  .drib_mask {
    transform: scale(1.1);
  }
  .svg-mask-common > circle,
  .svg-mask-common > rect {
    fill: #fff;
  }


  .page_content {
    z-index: 5;
    position: relative;
  }
  .block {
    position: relative;
    z-index: 5;
    width: 460px;
    margin: 0 auto;
    transform: translateX(66%);
  }
  .phones {
    margin: 0 0 32px;
    .title {
      font-weight: 300;
      margin: 0 0 7px;
    }
    a {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
  }
  .email {
    color: $y;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .address {
    font-size: 14px;
    font-weight: 300;
    line-height: 23px;
    margin: 36px 0 0;
    cursor: pointer;
    div {
      color: $y;
      font-size: 16px;
      font-weight: 700;
    }
    &:hover {
      div {
        text-decoration: underline;
      }
    }
  }
  form {
    margin: 137px 0 0;
    .title_form {
      font-size: 18px;
      font-weight: 700;
      margin: 0 0 30px;
    }
    .flex_field {
      display: flex;
      justify-content: space-between;
      .field {
        width: 48%;
      }
    }
    .button {
      display: flex;
      margin: 43px 0 0;
      align-items: center;
      position: relative;


      $animationLength: 1.5s;
      $animationRadius: 12px;
      $dotSize: 5px;

      .sampleContainer {
        position: absolute;
        left: 0;
        top: -4px;
        opacity: 0;
        transition: .1s;
        &.show {
          opacity: 1;
        }
      }

      .loader_form {
          position: relative;
          width: $animationRadius * 3 + $dotSize;
          height: $dotSize;
          margin: $animationRadius auto;
      }

      .dot {
          display: inline-block;
          width: $dotSize;
          height: $dotSize;
          border-radius: $dotSize * 0.5;
          background: #ccc;
          position: absolute;
      }

      .dot_1 {
          animation: animateDot1 $animationLength linear infinite;
          left: $animationRadius;
          background: $y;
      }

      .dot_2 {
          animation: animateDot2 $animationLength linear infinite;
          animation-delay: $animationLength / 3;
          left: $animationRadius * 2;
      }

      .dot_3 {
          animation: animateDot3 $animationLength linear infinite;
          left: $animationRadius;
      }

      .dot_4 {
          animation: animateDot4 $animationLength linear infinite;
          animation-delay: $animationLength / 3;
          left: $animationRadius * 2;
      }

      @keyframes animateDot1 {
          0%{ transform: rotate(0deg) translateX(-$animationRadius); }
          25% { transform: rotate(180deg) translateX(-$animationRadius); }
          75% { transform: rotate(180deg) translateX(-$animationRadius); }
          100% { transform: rotate(360deg) translateX(-$animationRadius); }
      }

      @keyframes animateDot2 {
          0%{ transform: rotate(-0deg) translateX(-$animationRadius); }
          25% { transform: rotate(-180deg) translateX(-$animationRadius); }
          75% { transform: rotate(-180deg) translateX(-$animationRadius); }
          100% { transform: rotate(-360deg) translateX(-$animationRadius); }
      }

      @keyframes animateDot3 {
          0%{ transform: rotate(0deg) translateX($animationRadius); }
          25% { transform: rotate(180deg) translateX($animationRadius); }
          75% { transform: rotate(180deg) translateX($animationRadius); }
          100% { transform: rotate(360deg) translateX($animationRadius); }
      }

      @keyframes animateDot4 {
          0%{ transform: rotate(-0deg) translateX($animationRadius); }
          25% { transform: rotate(-180deg) translateX($animationRadius); }
          75% { transform: rotate(-180deg) translateX($animationRadius); }
          100% { transform: rotate(-360deg) translateX($animationRadius); }
      }

    }
    .success {
      color: #a3a3a3;
      font-weight: 300;
      transition: .3s;
      &.hidden{
        opacity: 0;
        pointer-events: none;
      }
    }
    button {
      cursor: pointer;
      background: none;
      position: relative;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: .2em;
      display: inline-block;
      padding-left: 46px;
      margin: auto 0 auto auto;
      transition: .3s;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
        height: 1px;
        width: 30px;
        background: $y;
        transition: .3s;
      }
      &:hover {
        color: $y;
        &:after {
          width: 46px;
          left: -16px;
        }
      }
    }
  }
}
#map {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  width: 39%;
}



.field {
  position: relative;
  margin: 0 0 22px;
  &.textarea_field {
    margin: 14px 0 0;
    textarea {
      display: none;
    }
  }
  label {
    position: absolute;
    left: 0;
    top: 6px;
    color: #a2a2a2;
    font-weight: 300;
    transition: .3s;
    pointer-events: none;
  }
  .textarea,
  input {
    border-bottom: 1px solid #d6d6d6;
    background: none;
    line-height: 18px;
    width: 100%;
    padding: 9px 0;
    font-weight: 300;
  }
  .textarea {
    max-height: 200px;
    overflow-y: auto;
  }
  &.not-empty {
    .textarea,
    input {
      border-color: #715044;
    }
    label {
      top: -13px;
      font-size: 12px;
    }
  }
  &.error {
    .textarea,
    input {
      border-color: red;
    }
  }

}


/*
#preloader {
  opacity: 1 !important;
  pointer-events: auto !important;
}*/


#drib_logo_1,
#drib_logo_2,
#drib_logo_3,
#drib_logo_4 {
  opacity: 0;
  transition: .4s;
}

#drib_logo_1 {
  transform: translate(17px,-10px);
}
#drib_logo_2 {
  transform: translate(-10px,-17px);
  transition-delay: .6s;
}
#drib_logo_3 {
  transform: translate(-17px,10px);
  transition-delay: .4s;
}
#drib_logo_4 {
  transform: translate(10px,17px);
  transition-delay: .2s;
}


#content_mini {
    width: 190px;
    height: 70px;

    position: absolute;
    top: 50%;
    left: 50%;
    margin: -35px 0 0 -95px;


    opacity: 1;
    transition: .4s;
    .text {
      fill: #0a5993;
      transition: .7s .8s;
      transform: translateX(-40px);
      opacity: 0;
    }
}

.preload_mini_go {
  #drib_logo_1,
  #drib_logo_2,
  #drib_logo_3,
  #drib_logo_4 {
    opacity: 1;
    transform: translate(0,0);
  }
  #content_mini .text {
    transform: translateX(0);
    opacity: 1;
  }
}

.preload_mini_end {
  #content_mini {
    width: 102px;
    height: 38px;
    margin: -19px 0 0 -51px;
    transition: .5s;
    left: 115px;
    top: 84px;
    svg {
      transition: .5s;
      width: 102px;
      height: 38px;
    }
  }

  #preloader {
    opacity: 0;
    pointer-events: none;
    transition-delay: .4s;
  }
}





#full_screen .drib_svg {
  transform: translate(-40px, -50px);
  opacity: 0;
  transition: .6s .3s;
  transform-origin: 50%;
}
.preload_mini_go {
  .description_page {
    transform: translate(0);
    opacity: 1;
  }
  #full_screen .drib_svg {
    transform: translate(0);
    opacity: 1;
  }
}



#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: .5s;
}
#bg_preload {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
}

#content_preload {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-9%,-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1062px;
  opacity: 0;
}

#logo_preload {
  width: 18%;
  position: relative;
  z-index: 5;
  svg {
    max-width: 100%;
  }
  .text {
    fill: #0a5993;
  }
}
#line_preload {
  width: 1px;
  height: 0;
  background-color: #c8c8c8;
  margin: 0 65px;
  transition: .6s;
  transition-delay: .6s;
  position: relative;
  z-index: 4;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -900px;
    width: 900px;
    background: #f5f5f5;
  }
}

#text_preload {
  width: 70%;
  height: 180px;
  max-width: 100%;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform .6s, opacity .4s;
  transition-delay: .9s;
  position: relative;
  z-index: 2;
}

#text_tag_preloader {
  font-size: 67px;
  font-weight: 900;
  line-height: 143px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: .1em;
}



.preload_go {
  #content_preload {
    transform: translate(-50%,-50%);
  }

  #line_preload {
    height: 176px;
  }
  #text_preload {
    opacity: 1;
    transform: translateX(0);
  }
}


.preload_end {
  #text_preload {
    opacity: 0;
    /*transform: translateX(-100%);*/
    transition-delay: 0s;
    transition: .4s;
  }
  #line_preload {
    width: 1px;
    height: 0;
    background-color: #c8c8c8;
    margin: 0 65px;
    transition: .4s;
    transition-delay: .4s;
    position: relative;
    z-index: 4;
  }

  #content_preload {
    /*transform: translate(-9%,-50%);*/
    transition: .6s;
    transition-delay: .4s;
  }

}

.logo_go_top {
  #preloader {
    pointer-events: none;
  }

  .description_page {
    transform: translate(0);
    opacity: 1;
  }
  #full_screen .drib_svg {
    transform: translate(0);
    opacity: 1;
  }

  #text_preload {
    height: 38px;
    transition-delay: 0s;
  }
  #content_preload {
    top: 65px;
    left: 64px;
    height: 38px;
    transform: translate(0);
    transition: .5s;
  }
  #logo_preload {
    width: 102px;
    height: 38px;
    transition: .5s;
    margin: auto auto auto 0;
    svg {
      width: 102px;
      height: 38px;
      transition: .5s;
      .text {
        fill: #fff;
      }
    }
  }
  &.dark {
    #logo_preload {
      svg {
        .text {
          fill: #0a5993;
        }
      }
    }
  }
  #bg_preload {
    opacity: 0;
    transition: .8s;
  }
  #logo {
    transition: .2s;
    opacity: 1;
    transition-delay: .5s;
  }
}

















.pswp__container_transition {
  -webkit-transition: -webkit-transform 333ms cubic-bezier(0.4, 0, 0.22, 1);
  transition: transform 333ms cubic-bezier(0.4, 0, 0.22, 1);
}






/*.gradient_animation {
  background: #fff;
  overflow: hidden;
  i {
    opacity: 0;
    width: 100%;
    height: 400%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 100%, 0);
    background: linear-gradient(to top, #000 0%, #000 25%, $y 50%, $y 75%, #FFF 100%);
    transition: all 3s ease;
    &.mini {
      transition: all 1.5s ease;
    }
  }
  .bg_animation {
    position: relative;
    z-index: 1;
    background: #fff;
    overflow: hidden;
  }
}*/
#next_service {
  .drib_svg {
    transform: translate(-30px, -60px);
    opacity: 0;
    transition: transform 1.5s .9s ease, opacity 1.3s .9s ease;
  }
  .animate {
    .drib_svg {
      transform: translate(0);
      opacity: 1;
    }
  }
}
.animate_block {
  opacity: 0;
  transition: all 0.8s linear;
  .item_animate {
    transform: translate3d(0, 80px, 0);
    opacity: 0;
    transition: transform 1.5s ease, opacity 1s ease;
    &:nth-child(2) {
      transition-delay: .2s;
    }
    &:nth-child(3) {
      transition-delay: .4s;
    }
    &:nth-child(4) {
      transition-delay: .6s;
    }
    &:nth-child(5) {
      transition-delay: .8s;
    }
    &:nth-child(6) {
      transition-delay: 1s;
    }
    &:nth-child(7) {
      transition-delay: 1.2s;
    }
    &:nth-child(8) {
      transition-delay: 1.4s;
    }
    &:nth-child(9) {
      transition-delay: 1.6s;
    }
    &:nth-child(10) {
      transition-delay: 1.8s;
    }
  }
  .barrel {
    img {
      transform-origin: 0 100%;
      transform: scaleY(0);
      opacity: 0;
      transition: .3s;
      &:nth-child(2) {
        transition-delay: .1s;
      }
      &:nth-child(3) {
        transition-delay: .2s;
      }
      &:nth-child(4) {
        transition-delay: .3s;
      }
      &:nth-child(5) {
        transition-delay: .4s;
      }
      &:nth-child(6) {
        transition-delay: .5s;
      }
      &:nth-child(7) {
        transition-delay: .6s;
      }
      &:nth-child(8) {
        transition-delay: .7s;
      }
      &:nth-child(9) {
        transition-delay: .8s;
      }
    }
  }
  .text_animate {
    transform: translateY(30px);
    opacity: 0;
    transition: transform 1.5s ease,opacity 1s ease;
  }
  .text_animate1 {
    transition-delay: .4s;
  }
  .text_animate2 {
    transition-delay: .6s;
  }
  .text_animate3 {
    transition-delay: .8s;
  }
  .text_animate4 {
    transition-delay: .9s;
  }

  &.item_partners {
    transform: translateY(70px);
    opacity: 0;
    transition: transform 1.5s ease,opacity 1s ease;
  }
  .item_partners1 {
    transition-delay: .2s;
  }
  .item_partners2 {
    transition-delay: .4s;
  }
  .item_partners3 {
    transition-delay: .6s;
  }
  .item_partners4 {
    transition-delay: .8s;
  }


  &.animate {
    opacity: 1;
    .item_animate {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    .barrel {
      img {
        transform: scaleY(1);
        opacity: 1;
      }
    }
    .text_animate {
      transform: translateY(0);
      opacity: 1;
    }
    .gradient_animation {
      i {
        transform: translate3d(0, 0, 0);
      }
    }
    &.item_partners {
      opacity: 1;
      transform: translateY(0);
    }
    &.statistics .item {
      transform: translateY(0);
      opacity: 1;
    }
  }
}







@keyframes liquid_top {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 40px);
  }
  100% {
    transform: translate(0, 0);
  }
}

#liquid_top_2,
#liquid_top {
  transform-origin: 0 23px;
  animation: 4s linear 0s normal none infinite liquid_top;
}






/* fles */
@keyframes fles {
  0% {
    transform: translate(0, 30px);
  }
  50% {
    transform: translate(0, -26px);
  }
  100% {
    transform: translate(0, 30px);
  }
}

#container_svg_1 {
  &.go {
    animation: 5s linear 0s normal none infinite fles;
  }
}

@keyframes ship2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(4px, 8px);
  }
  50% {
    transform: translate(8px, 4px);
  }
  75% {
    transform: translate(4px, 8px);
  }
  100% {
    transform: translate(0, 0);
  }
}

#ship,
#ship_2,
#ship-bulk {
  &.go {
     animation: 5s linear 0.5s normal none infinite ship2;
  }
}


@keyframes flask_liquid_top {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
#flask {
  &.go {
    #oliya_top {
      animation: 4s linear 0s normal none infinite flask_liquid_top;
    }
  }
}

//bulk-cargo-transshipment
#b-cargo-t #corn-in-barrel.go {
  animation-name: b_cargo_t_corn;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0ms;
}

@keyframes b_cargo_t_corn {
  0% {
    transform: translateY(-18px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(-18px);
  }
}

#b-cargo-t  #grain-on-board.go {
  animation-name: b_cargo_t_corn_on_board;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0ms;
}

@keyframes b_cargo_t_corn_on_board {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-26px);
  }
  100% {
    transform: translateY(0);
  }
}


#loose-grain{
  animation-name: b_cargo_t_corn_loosing;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0ms;
}

@keyframes b_cargo_t_corn_loosing {
  0% {
    transform: translate(4px, 2px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(4px, 2px);
  }
}


.error_404 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto !important;
  padding: 359px 0 228px;
  position: relative;
  .title_page {
    margin: 0 0 58px;
    padding-top: 15px;
  }
  .svg-mask-common {
    pointer-events: none;
  }

  #drib_border_1,
  #drib_border_2 {
    transform: scale(1.3);
    opacity: 1;
  }
  .drib_mask {
    transform: scale(1.1);
  }
  .svg-mask-common > circle,
  .svg-mask-common > rect {
    fill: #fff;
  }
  h1 {
    font-size: 189px;
    font-weight: 900;
    line-height: 53px;
    letter-spacing: .2em;
    margin-bottom: 49px;
  }
  h2 {
    font-size: 16px;
    font-weight: 300;
    line-height: 84px;
    margin: 0 0 113px;
  }
  .link {
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: .2em;
    display: inline-block;
    padding-left: 46px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -1px;
      height: 1px;
      width: 30px;
      background: $y;
        transition: .3s;
      }
      &:hover {
        color: $y;
        &:after {
          width: 46px;
          left: -16px;
        }
      }
  }
  .page {
    position: relative;
    z-index: 5;

  }
}







.page_fullscren {
  display: flex;
  padding: 100px 16px 30px;
  height: 100%;
  position: relative;
  & > div {
    width: 50%;
    position: relative;
    z-index: 6;
    padding: 0 50px 0 0;
  }
  .description_page {
    margin: auto 0;
    padding: 0;
    .text {
      width: 600px;
      max-width: 100%;
    }
    .title_page {
      font-size: 36px;
      line-height: 36px;
      margin: 22px 0 50px;
    }
    p {
      color: #010101;
      font-size: 14px;
      font-weight: 300;
      line-height: 30px;
    }
    .breadcrumbs {
      font-size: 14px;
      &:after {
        background: #ef7b22;
      }
    }
  }
  .svg-mask-common  {
    pointer-events: none;
    z-index: 0;
  }

  #drib_border_1,
  #drib_border_2 {
    transform: scale(1.3);
    opacity: 1;
  }
  .drib_mask {
    transform: scale(1.1);
    fill: #ebebeb;
  }
  .svg-mask-common > circle,
  .svg-mask-common > rect {
    fill: #fff;
  }
}


  .instead_tooltip {
    display: none;
  }


























