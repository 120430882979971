@media all and (max-width: 1440px) {
	.responsive_wrap {
		flex-grow: 1;
		.content_resp {
			display: flex;
		}
	}
	#header_navigation {
		position: relative;
		width: auto;
		margin: 0 0 0 21px;
	}
}

@media all and (max-width: 1340px) {
	#header_navigation {
		margin: 0 0 0 48px;
		.item {
			margin: 0;
		}
	}
	#full_screen .drib_svg {
		top: -253px;
	}
	.slide_main h2 {
		font-size: 36px;
		line-height: 42px;
		svg {
			top: -76px;
			left: -16px;
		}
	}
}

@media all and (max-width: 1300px) {
	.statistics .item {
		width: 30%;
		margin: 0 5% 73px 0;
	}
	#slider_text_title .title_page {
		font-size: 26px !important;
		line-height: 30px !important;
	}
	#full_screen .about_page .flex_block .right {
		margin: auto 0 0 auto;
	}
}

@media all and (max-width: 1230px) {
	#header_navigation {
		margin: 0 0 0 37px;
		a {
			margin: 0 4px;
		}
	}
	.flex_block {
		.icon_list_col {
			padding: 0;
			width: 40%;
		}
		.text_block {
			width: 56%;
		}
	}
	.text_block_big .text_block {
		width: 48%;
	}
}


@media all and (max-width: 1200px) {
	.contact_page .block {
		transform: translateX(40%);
	}
}





@media all and (min-width: 886px) and (max-width: 1130px) {
	#full_screen .ill_top.about_ill {
		align-items: center;
		margin: auto 0;
	}
	#full_screen .ill_top.about_ill #about_svg {
		/*height: auto !important;*/
		width: 100% !important;
		position: static;
	}
}

@media all and (max-width: 1130px) {
	#full_screen .ill_top.about_ill #about_svg {
		position: static;
	}
	#full_screen .ill_top.about_ill {
		height: auto !important;
		justify-content: flex-end;
		display: flex;
		width: 50% !important;
		position: relative;
		top: 0;
		left: 0;

	}
	#full_screen .ill_top.about_ill .about_p {
		display: none;
	}
	#full_screen .about_page .flex_block .right {
		margin: auto 0;
	}
	#content_preload {
		transform: translate(0) !important;
		display: block;
		text-align: center;
		width: 100%;
		height: 100%;
		position: static;
	}
	#line_preload {
	  display: none;
	}

	#logo_preload {
    width: 44%;
    height: 50px;
    margin: 0;
    transform: translate(-54%,-181%);
    position: fixed;
    top: 50%;
    left: 50%;
	}

	#text_preload {
		width: 84%;
		height: auto;
	  opacity: 1;
	  transform: translate(-50%, 0) !important;
	  position: fixed;
	  z-index: 2;
	  top: 50%;
	  left: 50%;
	}
	
	

	.preload_end {
	  #text_preload {
	    opacity: 0;
	    transition: .4s;
	   	transform: translate(-50%, 0) !important;
	  }
	  #content_preload {
	    transform: translate(0) !important;
	  }
	  
	}

	.logo_go_top {

	  #text_preload {
	    height: 38px;
	    transition-delay: 0s;
			transform: translate(-50%, 0) !important;
	  }
	  #content_preload {
	    top: 65px;
	    left: 64px;
	    height: 38px;
	    transform: translate(0) !important;
	    transition: .5s;
	  }
	  #logo_preload {
	    width: 102px;
	    height: 38px;
	    margin: 0;
	    top: 21px;
	    left: 26px;
	    transform: translate(0, 0) !important;
		}
	}





}


@media all and (max-width: 1200px) {



	header {
		#header_navigation .drop_menu {
			position: static;
			line-height: 24px;
			margin: 8px 0 0;
			a {
				display: block;
				opacity: 1;
				margin: 0 0 0 !important;
				font-size: 8px !important;
				text-align: center;
			}
		}
		.responsive_wrap {
			position: fixed;
			background: #fff;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			padding: 70px 26% 10px;
			overflow: hidden;
			overflow-y: auto;
			opacity: 0;
			pointer-events: none;
			transition: .6s;
			.content_resp {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}
			#header_navigation {
				margin: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: static;
				flex-grow: 1;
				.item_drop,
				a {
					margin: 0 0 16px;
					&:not(.active) {
						color: #000;
					}
				}
				.item_drop {
					margin: 0 0 14px;
				}
			}
			a {
				color: #000;
			}

			.header_contact {
			  display: flex;
			  justify-content: space-between;
			  margin-bottom: 19px;
			  a {
			    display: inline-flex;
			    align-items: center;
			    margin-left: 50px;
			    font-size: 12px;
			    font-weight: 300;
			    text-transform: none;
			    letter-spacing: 0;
			    padding: 0;
			    margin: 0;
			    img {
			    	margin-right: 8px;
			    }
			  }
			}
			.bot_resp {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-grow: 0;
				a {
					top: 0;
					margin: 0;
					order: 2;
				}
				.pdf {
					right: -13px;
					font-size: 12px;
					font-weight: 400;
					text-transform: none;
				}
			}
			#lang {
				top: 0;
				order: 1;
				left: -7px;
				a {
					font-size: 12px;
					&:not(.active) {
						color: #434343;
					}
				}
			}
		}
	}
	.open_menu {
		.responsive_wrap {
			opacity: 1;
			pointer-events: auto;
			.drop_menu {
				pointer-events: auto !important;
			}
		}
		#menu_btn {
			span {
				background: #000;
			}
			.top {
				top: 8px;
				transform: rotate(45deg);
				left: 2px;
			}
			.middle {
				width: 0;
				left: 18px;
			}
			.bottom {
				top: 8px;
				width: 100%;
				transform: rotate(-45deg);
				left: 2px;
			}
		}
		#logo {
			.text {
				fill: #005991 !important;
			}
		}
	}
	.statistics .item .top {
		font-size: 48px;
		line-height: 36px;
	}
	#menu_btn {
		display: block;
		z-index: 10;
	}
}


@media all and (max-width: 1030px) {

	.power_growth .item {
		display: block;
		.text {
			margin: 19px 0 0;
		}
		.barrel {
			display: flex;
			margin-right: 0;
			align-items: center;
			img {
				width: 7%;
				height: none;
			}
		}
	}
	.contact_page {
		padding: 144px 0 0;
		#svg_mask {
			display: none;
		}
		.page_content,
		.block {
			width: 100%;
			padding: 0 26px;
			transform: translateX(0);
		}
		form {
			margin: 50px 0;
		}
	}
	#map {
		position: relative !important;
		width: 100%;
		height: 320px;
	}
}

@media all and (max-width: 1024px) {
	.top_part_about .about_block .top,
	.top_part_about .about_block .top span {
		font-size: 18px !important;
		line-height: 24px !important;
		margin-right: 0 !important;
		display: inline !important;
	}
	.error_404 {
		padding: 260px 0 228px;
	}
	.slide_main h2 {
		font-size: 28px;
		line-height: 36px;
	}
	#under .services .text p {
		font-size: 12px;
	}
	.video_index {
		display: none;
	}
	#slides_main_wrapp {
		.content_slide_main {
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
		.slide_main[data-slide="1"] {
			.content_slide_main {
				background-image: url(../img/slide1.jpg);
			}
		}
		.slide_main[data-slide="2"] {
			.content_slide_main {
				background-image: url(../img/slide2.jpg);
			}
		}
		.slide_main[data-slide="3"] {
			.content_slide_main {
				background-image: url(../img/slide3.jpg);
			}
		}
		.slide_main[data-slide="4"] {
			.content_slide_main {
				background-image: url(../img/slide4.jpg);
			}
		}
	}
}

@media all and (max-width: 1000px) {
	.flex_block > div {
		width: 48%;
	}
	.deep_water {
		.ship {
			width: 50%;
			img {
				width: 100%;
			}
		}
		.text {
			width: 40%;
		}
	}
}

@media all and (max-width: 930px) {
	.top_part_about {
		display: block;
		& > div {
			width: 100%;
		}
		.about_block {
			margin-bottom: 60px;
			.top {
				display: flex;
				align-items: flex-end;
				span {
					flex-shrink: 0;
					line-height: 48px;
					margin-right: 15px;
				}
			}
		}
	}
	footer .top nav a {
		margin-right: 26px;
	}
}

@media all and (max-width: 920px) {
	#full_screen .title_page {
		font-size: 36px;
		line-height: 38px;
	}
	.tooltip {
		display: none;
	}
	.instead_tooltip {
		display: block;
	}
}

@media all and (max-width: 885px) {

	
	.preload_mini_end {
	  #content_mini {
	    left: 77px;
	    top: 40px;
	  }
	}

	.index_page #under .title_page {
		font-size: 30px;
		text-align: center !important;
	}
	#under .services {
		padding-top: 48px;
		.text {
			padding: 26px 0 0;
			.title {
				font-size: 26px;
				line-height: 30px;
			}
		}
	}
	footer {
		background: $y;
		.bot {
			padding: 23px 0;
		}
	}
	.contact_page {
		padding: 144px 0 0;
		.block {
			padding: 0 26px;
		}
	}
	.breadcrumbs {
		display: none;
	}
	.page_dynamic_single .breadcrumbs {
		display: block;
	}
	.container {
		padding: 0 !important;
	}
	footer {
		padding: 0 26px;
	}
	.internal_pages #full_screen {
		height: auto !important;
	}
	#full_screen .page_content {
		width: 100%;
	}
	#full_screen .drib_svg {
		top: -335px;
	}
	#full_screen .svg_color {
		fill: #ebebeb !important;
	}
	.internal_pages #full_screen .page .flex_block {
		flex-wrap: wrap;
		& > div {
			width: 100% !important;
		}
	}
	.page_fullscren {
		flex-wrap: wrap;
		height: auto;
		& > div {
			padding: 0;
			width: 100%;
		}
	}
	
	.page_fullscren #svg_mask {
		top: -40%;
		left: -14%;
	}

	.internal_pages #full_screen .ill_top {
		margin-top: 30px;
	}
	header {
		padding: 0 26px;
		top: -81px;
		&:after {
			left: 0;
			right: 0;
		}
	}
	.internal_pages #full_screen {
		.page {
			padding-top: 111px !important;
		}
		#h_and_s {
		    /*width: 650px !important;
		    height: 440px !important;*/
		    width: 100%!important;
		    height: auto!important;
	  	}
		#laboratory_svg {
		    /*width: 490px !important;
		    height: 500px !important;*/
		    width: 100%!important;
		    height: auto!important;
		}
		#fles {
		    /*width: 574px !important;
		    height: 468px !important;*/
		    width: 100%!important;
		    height: auto!important;
		}
		#about_svg {
		    /*width: 655px !important;*/
		    /*height: 468px !important;*/
		    width: 100%!important;
		    height: auto!important;
		}
	}
	.internal_pages #full_screen .ill_top {
		justify-content: center;
		order: 2;
		display: block !important;
	}
	.internal_pages #full_screen .description_page {
		order: 1;
		margin: 0 0 10px;
	}
	#full_screen .page_content {
		margin-bottom: 0;
	}
}

@media all and (max-width: 850px) {
	#full_screen .tooltip .text {
		left: -101px;
		top: auto;
		bottom: 72px;
	}
	#full_screen .tooltip.open {
		z-index: 11;
	}
	#full_screen .tooltip.open .text {
		left: -101px;
		bottom: 62px;
	}
	#drib_vac {
		width: 360px!important;
	    height: 582px!important;
	    top: -389px;
	    left: -150px;
	    path:last-child {
	    	fill: #ebebeb!important;
	    }
	}
	header:after {display: none;}
	.icon_list .item:nth-child(3n),
	.icon_list .item,
	.statistics .item:nth-child(3n),
	.statistics .item {
		width: 47%;
		margin-right: 5%;
	}
	.icon_list .item:nth-child(2n),
	.statistics .item:nth-child(2n) {
		margin-right: 0;
	}

	.range_block {
		.top {
			margin: 0 0 7px;
		}
		.year {
			font-size: 30px;
		}
	}
	.text_block h3 {
		padding: 0 0 5px;
	}
	.text_block_big .flex_block {
		margin: 0 0 18px;
	}
}

@media all and (max-width: 890px) {
	footer .top nav {
		display: none;
	}
	footer .top .right {
		width: 100%;
		display: flex;
		justify-content: space-between;
		a {
			margin: 0;
		}
	}
}

@media all and (max-width: 800px) {
	.flex_block.page {
		flex-wrap: wrap;
		
		.icon_list_col,
		.text_block {
			width: 100%;
		}
		.text_block {
			margin-bottom: 32px;
		}
	}
}

@media all and (max-width: 760px) {
	.partners {
		white-space: nowrap;
		display: block;
		text-align: left;
		height: auto;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		padding: 50px 26px;
		left: -26px;
		width: calc(100% + 52px);
		.item_partners {
			display: inline-block;
			width: auto;
			margin: 0 34px 0 0;
			vertical-align: middle;
			img {
				filter: grayscale(0);
			}
		}
	}
	.index_page #under .partners {
		padding: 14px 20px 0;
	}
	.files_block {
		margin: 0 auto 30px;
	}

	#under .services .item:last-child {
		margin-bottom: 46px;
	}
}

@media all and (max-width: 750px) {
	#next_service .ill_ns {
		display: none;
	}
	#next_service .left {
		width: 100%;	
	}
	.deep_water {
		.block {
			flex-wrap: wrap;
		}
		.ship {
			margin-top: 55px;
			width: 100%;
			order: 2;
		}
		.text {
			order: 1;
			width: 100%;
		}
	}
	.slide_main .text {
		margin: 0 0 58px 134px;
	}
	.item_nav_slides_main.active:before {
		display: none;
	}
	#nav_slides_main {
		left: 23px;
	}
}

@media all and (max-width: 720px) {
	.error_404 #svg_mask {
		display: none;
	}
	.error_404 h2 {
		margin: 0 0 40px;
	}
	#nav_text_slider {
		padding-left: 0;
		&:after {
			content: none;
		}
	}
	#slider_text_title .title_page {
		font-size: 18px !important;
		font-weight: 700;
		line-height: 30px !important;
	}
	.image_page .bot, .image_page .top {
		width: 720px;
		left: 50%;
		margin-left: -360px;
	}
	.saving_block {
		padding: 66px 0 6px;
		.title_page {
			padding: 0 0 49px!important;
		}
		.block {
			display: block;
			.item {
				width: 100%;
				margin: 0 0 40px 0;
			}
		}
	}
	.work_principles {
		padding: 52px 0 9px;
		.title_page {
			margin: 0 0 19px!important;
		}
		.block {
			display: block;
			.item {
				width: 100%;
				margin: 0 0 40px;
			}
		}
	}
	.page_dynamic_single .text_content table {
		width: 100%;
	}
}

@media all and (max-width: 600px) {
	
	#arrow {
		/*bottom: 18px;
		&:after {
			display: none;
		}*/
	}

	#slider_text_title .title_page {
		font-size: 30px !important;
		line-height: 38px !important;
	}

	#slider_text_title {
		margin-left: 0;
	}
	
	.drib_mask {
		transform: scale(18) translate(0);
	}
	.go_drib .drib_mask {
		transform: scale(2) translate(-3%,-13%);
	}
	.go_drib {
		.content_slide_main:before {
			background: rgba(255,255,255,.5);
		}
	}

	#drib_border_1, #drib_border_2,
	#drib_1, #drib_2, #drib_3, #drib_4, #play {
		display: none;
	}
	.index_page #full_screen {
		overflow: hidden;
		/*display: block;*/
		.page {
			padding: 0 26px 0;
		}
		#svg_mask {
			display: none;
		}
		#svg_mask_resp {
			display: block;
		}
	}
	.index_page #under .partners {
		padding: 22px 26px 50px;
	}
	#under .services {
		padding-top: 35px;
	}
	#under .services .item {
		flex-wrap: wrap;
		margin-bottom: 60px;
		.ill {
			order: 1;
			width: 100%;
			text-align: center;
		}
		.text {
			order: 2;
			width: 100%;
			text-align: left;
		}
		.title {
			font-size: 18px;
			line-height: 30px;
		}
		p {
			margin: 12px 0 23px;
		}
	}

	.contact_page .address div,
	.contact_page .email {
		font-weight: 500;
	}
	.contact_page {
		padding: 100px 0 0;
		.title_page {
			font-size: 18px;
			line-height: 30px;
			margin: 0 0 35px;
		}
		.address div {
			font-size: 14px;
		}
		form .flex_field {
			flex-wrap: wrap;
			.field {
				width: 100%;
			}
		}
		.phones a {
			font-size: 18px;
		}
	}
	.files_block {
		.file {
			width: 100%;
			margin-bottom: 30px;
		}
	}
	.title_page {
		letter-spacing: 0 !important;
	}
	.page_dynamic {
		margin: 106px auto 30px;
		.title_page {
			font-size: 18px !important;
			line-height: 30px;
			margin: 0;
		}
	}
	.articles .item {
		.title {
			line-height: 24px;
		}
	}
	.power_growth {
		padding: 47px 0 50px;
		.title {
			margin: 0 0 40px;
		}
		.item {
			margin: 0 0 45px;
		}
	}
	.top_part_about{
		padding-bottom: 1px;
	}
	.deep_water {
		.title_page {
			margin: 0 0 21px!important;
		}
		.text .item2 .top,
		.text .top {
			margin-bottom: 14px;
		}
		.text .item1 {
			margin-bottom: 7px;
		}
	}
	#under .title_page {
		text-align: left;
	}
	.text_block_big {
		.title_page {
			margin: 0 0 11px;
		}
		.flex_block {
			display: block;
			& > div {
				width: 100%;
			}
		}
		.text_block {
			margin: 0 0 24px;
		}
	}
	.text_block_big+.text_block {
		padding-top: 0;
		p {
			color: #5c5c5c;
		}
	}
	.pagination_gallery {
		display: none;
	}
	.gallery_block .item {
		margin: 0 8px 0 0;
	}
	.gallery_page.gallery_service {
		padding: 71px 26px 24px;
		position: relative;
		z-index: 3;
	}
	.flex_block.page {
		padding: 47px 26px 27px;
	}
	#under .title_page.tal {
		margin-top: 30px;
	}
	#under .title_page,
	#full_screen .title_page {
		font-size: 18px;
		line-height: 30px;
	}
	.text_block {
		padding: 26px 26px 0;
		h2 {
			padding: 0 0 19px;
			font-size: 18px;
			line-height: 24px;
		}
	}
	.description_page {
		font-size: 14px;
	}
	.internal_pages #full_screen .title_page {
		margin: 0 0 27px;
	}
	.internal_pages #full_screen .description_page {
		margin: 0 0 18px;
	}
	#full_screen .drib_svg {
		width: 360px !important;
		height: 582px !important;
		top: -389px;
		left: -150px;
	}
	#next_service {
		overflow: visible;
	}
	#next_service .title {
		font-size: 18px;
		line-height: 24px;
	}
	#next_service .description_page {
		margin: 7px 0 12px;
	}
	#next_service .left {
		margin: 0;
	}

	.slide_main h2 {
		font-size: 18px;
		line-height: 30px;
	}
	#nav_slides_main {
		bottom: 18px;
	}
	#next_slide_main {
		width: 28px;
		height: 28px;
		margin-top: 0;
	}
	.slide_main .skip {
		padding-left: 0;
		letter-spacing: 0;
		&:after {
			content: none;
		}
	}
	.slide_main .text {
		margin: 3px 0 20px 75px;
	}
}


@media all and (max-width: 580px) {
	footer .bot #logo_footer {
		display: none;
	}
	header .responsive_wrap {
		padding: 70px 16% 10px;
	}
	header .responsive_wrap {
		padding: 70px 26px 10px;
	}
}

@media all and (max-width: 540px) {

	#full_screen .tooltip {
		left: 44%;
	}
	#full_screen .tooltip.tooltip_2 {
		left: 51%;
		top: 18%;
	}
	#full_screen .tooltip.tooltip_3 {
		left: 26%;
	}
	
	#arrow {
		bottom: 20px;
	}
	#arrow:after {
		display: none;
	}
	.error_404 {
		padding: 149px 0 128px;
	}
	.error_404 h1 {
		font-size: 100px;
		line-height: 100px;
		margin-bottom: 14px;
	}
	.error_404 h2 {
		line-height: 30px;
	}
	.icon_list .item,
	.statistics .item {
		margin-bottom: 50px;
		width: 100% !important;
		margin-right: 0 !important;
	}
	.icon_list .item {
		margin-bottom: 42px;
	}
	.icon_list .item {
		display: flex;
		.image {
			margin: 0 22px 0 0;
			flex-shrink: 0;
			width: 62px;
			justify-content: center;
			align-items: flex-start;
		}
	}
	.statistics {
		padding: 36px 26px 2px;
	}
	.icon_list .item:last-child {
		margin-bottom: 0;
	}
}

@media all and (max-width: 450px) {
	.top_part_about {
		padding: 30px 0 0;
	}
	.flex_block .about_block {
		margin-bottom: 30px;
	}
	.flex_block .about_block .top {
		font-size: 14px;
		margin-bottom: 6px;
		span {
			font-size: 38px;
			line-height: 38px;
		}
	}
	.pagination .page_content span {display: none;}
	footer {
		font-size: 11px;
	}
	footer .bot {
		display: block;
		text-align: center;
		.cop {
			margin: 0 0 14px;
		}
	}
	footer .top {
		padding: 35px 0 0px;
		border: 0;
		.phone {
			font-size: 10px;
		}
	}
	footer .top .right img {
		margin-right: 8px;
	}

	header .responsive_wrap {
		padding: 70px 26px 10px;
		min-width: 320px;
	}
}


@media all and (max-width: 400px) {
	footer .top .right,
	header .responsive_wrap .header_contact {
		flex-direction: column;
		align-items: center;
		a {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}







@media all and (max-height: 480px) {
	header .responsive_wrap .content_resp {
		height: auto;
	}
}