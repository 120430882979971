$y: #ef7b22;
$o: #e95224;
$b: #0a5993;
$l: #1c95c0;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    ::selection {
        background: #000;
        background-color: #000;
        color: #fff;
    }
}
html,body {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    min-width: 320px;
    -webkit-text-size-adjust: 100%;
}
body {
    background: #f5f5f5;
    &.no_scroll {
        overflow: hidden;
    }
}
.page {
    width: 1336px;
    margin: 0 auto;
    padding: 0 26px;
    max-width: 100%;
}
.page_content {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;  
}
ul, li {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}
input,
textarea,
button,
select {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    -webkit-appearance: none !important;
    border-radius: 0 !important;
}
a {
    transition: .3s;
    text-decoration: none;
    color: #000;
}
#all {
    overflow: hidden;
    width: 100%;
    height: auto;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
header,
main,
footer {
    width: 100%;
    max-width: 100%;
}
.tal {
    text-align: left;
}
.tar {
    text-align: right;
}

sup {
    vertical-align: top;
    position: relative;
    top: -.5em;
}
.top sup {
    top: .4em;
}